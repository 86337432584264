// This dialog appears for delivery driver volunteers or users doing delivery
// to a specific location.  They'll be able to send the email out to the users
// and it will complete placing the order for them.
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useCommunityOrder from "../../../../components/Firestore/useCommunityOrder";
import CreateVisibleCommunityOrders from "../../../../components/dialogs/functions/CreateVisibleCommunityOrders.js";
import LoadUserEmails from "../../../../components/dialogs/Components/SendUserEmails/LoadUserEmails.js";
import CommunityPickupAccordion from "../../../../pages/MyAccountPage/Components/Accordions/CommunityPickupAccordion.jsx";
import OrderDropOffMessage from "../../../../components/dialogs/Components/SendUserEmails/functions/OrderDropOffMessage.js";
import batchEmails from "../../../../functions/Email/BatchEmailer.js";
import FinalizeDeliveryFees from "../../../../components/dialogs/Components/SendUserEmails/functions/FinalizeDeliveryFees.js";
import SendingEmailsDialog from "../../../../components/dialogs/Components/SendUserEmails/SendingEmailsDialog.jsx";
import LoadingEmailsDialog from "../../../../components/dialogs/Components/SendUserEmails/LoadingEmailsDialog.jsx";
import SetBaseDeliveryFees from "../../../../components/dialogs/Components/SendUserEmails/functions/SetBaseDeliveryFees.js";
import OrderDropOffEmails from "../../../../components/dialogs/Components/SendUserEmails/functions/OrderDropOffEmails.js";

// This is the dialog box that opens when a user clicks the add button
function DeliveryVolunteerEmailsBox({
  open,
  onClose,
  handleSave,
  userEmails,
  checkList,
  setCheckList,
}) {
  // If the user closes or cancels then we will close the dialog and if they hit cancel
  // their changes are reset.
  const handleClose = () => {
    onClose(false);
  };

  // When the user changes the checklist
  const handleChange = (e) => {
    const target = e.target;
    setCheckList({ ...checkList, [target.name]: target.checked });
  };

  // When the user clicks select all for a community location.
  const handleSelectAll = (e) => {
    const target = e.target;
    const location = target.name;
    const locationEmails = Object.keys(userEmails[location]);
    const newCheckList = {};
    for (let i = 0; i < locationEmails.length; i++) {
      newCheckList[locationEmails[i]] = target.checked;
    }
    setCheckList({ ...checkList, ...newCheckList });
  };

  // Since we don't want the user moving to the next state until they've selected
  // users and created a message then we just check to make sure those conditions are
  // met and then set this variable to true which displays the next button.
  let nextButtonVisible = false;
  Object.keys(checkList).forEach((user) => {
    if (checkList[user]) {
      nextButtonVisible = true;
    }
  });

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Select the users you wish to send the email.
      </DialogTitle>
      <DialogContent>
        {Object.keys(userEmails).length === 0 ? (
          <p>There are currently no orders for this location.</p>
        ) : (
          <span>
            {Object.keys(userEmails).map((location, ind) => (
              <CommunityPickupAccordion
                key={ind}
                location={location}
                userEmails={userEmails}
                checkList={checkList}
                handleChange={handleChange}
                handleSelectAll={handleSelectAll}
              />
            ))}
          </span>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          CLOSE
        </Button>
        {nextButtonVisible && (
          <Button color="primary" onClick={() => handleSave()}>
            SEND
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

DeliveryVolunteerEmailsBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function DeliveryVolunteerEmailsDialog({
  date,
  year,
  communityLocation,
}) {
  const [open, setOpen] = useState(false);
  // Refined list of users with order details of users that are picking up from
  // community hubs.  This includes their donations, foodList, etc. straight from
  // communityOrders.
  const [communityHubUsers, setCommunityHubUsers] = useState({});
  // These order chunks are in case there are too many orders and there
  // are spill over documents.  We need to update these on any changes to the orders.
  const [communityOrderChunks, setCommunityOrderChunks] = useState({});
  // This is the base delivery fee that is charged for delivery.  The actual delivery
  // fee charged to each user is the base amount divided amongst the users.
  const [baseDeliveryFee, setBaseDeliveryFee] = useState({
    [communityLocation]: 0,
  });
  // This is the dictionary that contains the user's location, the user and their email
  // {location : {firstName-lastName-userId : email}}
  const [userEmails, setUserEmails] = useState({});
  // This is set to true while the program loads the customers' emails and set a
  // progress dialog.
  const [loadingEmails, setLoadingEmails] = useState(false);
  // This is set to true while the program sends the customers' emails and set a
  // progress dialog.
  const [sendingEmails, setSendingEmails] = useState(false);
  // This hook will store the user and their custom message {firstName-lastName-userId : customMessage + order}
  const [userDropOffMessages, setUserDropOffMessages] = useState({});
  const greeting = "Hi";
  // The message is just blank as we don't need the delivery drivers sending notes.
  const message = "";

  // The checkList of customers that the user has selected to message. {firstName-lastName-userId : true}
  const [checkList, setCheckList] = useState({});
  // The date in the format we need to load the document MM-DD-YYYY
  const formattedDate = `${String(
    parseInt(date.substring(0, 2), 10) + 1,
  ).padStart(2, "0")}-${date.substring(2, 4)}-${year}`;
  // Load the community order and then chunks that are with it.
  const { order, initialized, communityOrderChunksTemp } =
    useCommunityOrder(formattedDate);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // If the user confirms they wish to send the emails.
  const handleSave = async () => {
    // Creates the batch of emails to send off.
    const emailBatch = [];

    // Create an object of the userDropOffMessages to cycle through.
    const users = Object.keys(userDropOffMessages);

    // Since we have the imported orders the userEmails is a dictionary like this
    // {location : {user} : email}.  We don't need the location on this dialog so this
    // is a dictionary with just the {user : email}
    const userEmailsNoLocation = {};

    // Cycle through the userEmails to remove the locations and just have the {user : email}
    Object.keys(userEmails).forEach((location) => {
      Object.keys(userEmails[location]).forEach((user) => {
        userEmailsNoLocation[user] = userEmails[location][user];
      });
    });

    // Cycle through the user's with a drop off message and all the
    // parameters for each email. Push them to an array to send off the emails.
    for (const user of users) {
      emailBatch.push([
        userEmailsNoLocation,
        user,
        setSendingEmails,
        greeting,
        userDropOffMessages,
      ]);
    }

    // Call the batch email function that will handle the delays necessary
    // to sending the emails off without overloading the send mail function.
    await batchEmails({
      batchedParamsForEmailFunc: emailBatch,
      singularEmailFunc: OrderDropOffEmails,
    });

    // Add the delivery fees to the users.
    FinalizeDeliveryFees(
      communityHubUsers,
      checkList,
      order,
      null,
      baseDeliveryFee,
      communityOrderChunks,
      setCommunityOrderChunks,
    );

    // Close the dialog
    setOpen(false);
    // Reset the checklist.
    setCheckList({});
    // Close the sending emails progress dialog
    setSendingEmails(false);
  };

  // Creates the order drop off messages.
  useEffect(() => {
    OrderDropOffMessage(
      message,
      communityHubUsers,
      checkList,
      setUserDropOffMessages,
      baseDeliveryFee,
    );
  }, [checkList]);

  // Load the volunteer schedule from the database.
  useEffect(() => {
    // If the order hasn't been loaded yet do nothing.
    if (order !== undefined) {
      // Set the chunks
      setCommunityOrderChunks([...communityOrderChunksTemp]);
      // Create a dictionary with each distribution location as the key and then
      // all the users and their order details as the values.  {communityLocation : {user : order}}
      let communityHubUsersTemp = CreateVisibleCommunityOrders(
        order.communityOrders,
        null,
        null,
        false,
        [],
      );
      // We only want the communityLocation that the user is delivering to so we can remove
      // all the other hubs.
      communityHubUsersTemp = {
        [communityLocation]: communityHubUsersTemp[communityLocation] || {},
      };

      // This is the find the base delivery fee.
      const baseDeliveryFeeTemp = SetBaseDeliveryFees(communityHubUsers);
      setBaseDeliveryFee(baseDeliveryFeeTemp);
      // Set the community hubs.
      setCommunityHubUsers({ ...communityHubUsersTemp });
      // Loads all the users emails and controls the loading hooks.
      LoadUserEmails(
        {},
        {},
        setLoadingEmails,
        setUserEmails,
        communityHubUsersTemp,
      );
    }
  }, [order, initialized]);

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        SEND DROP OFF EMAIL
      </Button>

      <DeliveryVolunteerEmailsBox
        open={open}
        onClose={handleClose}
        handleSave={handleSave}
        userEmails={userEmails}
        checkList={checkList}
        setCheckList={setCheckList}
      />

      <LoadingEmailsDialog open={loadingEmails} />
      <SendingEmailsDialog open={sendingEmails} />
    </div>
  );
}
