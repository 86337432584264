// This is used to create a farmer's invoice.  It takes in the
// order and creates an invoice that can be downloaded as a word doc.

// This function calculates the total of the farm's order.
function UserGrandTotal(farmFoodList) {
  let grandTotal = 0;

  // Cycle through all the foodItems again find the product of the quantity
  // and the cost of the food item and then add it to the farms total
  farmFoodList.forEach((food) => {
    grandTotal += food.quantity * food.price;
  });

  return grandTotal;
}

// This function takes farm's order and generates an invoice.
export default function CreateFarmInvoice(
  farm,
  farmFoodList,
  farmList,
  selectedDate,
) {
  const farmInfo = farmList.find((f) => f.farmName === farm);

  // We want to generate a unique invoice number.  We take the
  // farm's name and turn it into a number by summing each letter's
  // ASCII number into a 4 digit number then we add the order date.
  let asciiSum = 0;
  for (let i = 0; i < farmInfo.farmName.length; i++) {
    asciiSum += farmInfo.farmName.charCodeAt(i);
  }
  const farmID = (asciiSum % 10000).toString().padStart(4, "0");
  const invoiceNumber = farmID + selectedDate;

  // The header of the invoice.
  const invoiceHeader = `
    <div style="position: relative; width: 100%;">
      
    </div>

    <table style="width: 100%; font-size: 12px; border-collapse: collapse; border: none;">
      <tr>
        <!-- Center the header -->
        <td style="text-align: center; vertical-align: middle; width: 100%; border: none;">
          <h1 style="margin: 0;">INVOICE</h1>
        </td>
        <!-- Logo on the right -->
        <td style="text-align: right; vertical-align: middle; width: 20%; border: none;">
          <img
            src="${farmInfo.farmerLogo}"
            width="40" height="40" />
        </td>
      </tr>
    </table>

    <table style="width: 100%; font-size: 12px; border-collapse: collapse; border: none;">
      <tr>
        <td style="text-align: left; vertical-align: top; width: 50%; border: none;">
          <p style="font-size: 15px;">
            ${farmInfo.farmName}<br/>
          </p>
          <p style="font-size: 12px;">
            <b>Phone :</b> ${farmInfo.farmNumber} <br/>
            <b>Email :</b>  ${farmInfo.farmerEmail}<br/>
            <b>Address :</b>  ${farmInfo.farmLocation}<br/>
          </p>
        </td>
        <td style="text-align: right; vertical-align: top; width: 50%; border: none;">
          <p style="margin: 0;">
            <b>Invoice #:</b> ${invoiceNumber}<br/>
            <b>Date:</b> ${selectedDate}<br/>
          </p>
        </td>
      </tr>
    </table>

    <table style="width: 100%; font-size: 12px; border-collapse: collapse; border: none;">
      <tr>
        <td style="text-align: left; vertical-align: top; width: 50%; border: none;">
          <p style="margin: 0;">
            <b>To:</b><br/>
            Jewish Family Services<br/>
            <b>Phone:</b> (604) 257-5151<br/>
            <b>Email:</b> farmtoplate@jfsvancouver.ca<br/>
            <b>Address :</b> 54 E 3rd Ave. Vancouver, BC V5T 1C3 <br/>
          </p>
        </td>
        <td style="text-align: right; vertical-align: top; width: 50; border: none;">
          <p style="margin: 0;">
            <b>For:</b><br/>
            Delivered on ${selectedDate}<br/>
          </p>
        </td>
      </tr>
    </table>
  `;

  // The body with the food items listed.
  const invoiceBody = `
    <table style="width:100%; border-collapse: collapse; font-size: 12px;">
      <thead>
        <tr style="background-color: #f2f2f2;">
          <th style="border: 1px solid #ddd; padding: 8px;">Item</th>
          <th style="border: 1px solid #ddd; padding: 8px;">Quantity</th>
          <th style="border: 1px solid #ddd; padding: 8px;">Farm</th>
          <th style="border: 1px solid #ddd; padding: 8px;">Price per Unit</th>
          <th style="border: 1px solid #ddd; padding: 8px;">Total Price</th>
        </tr>
      </thead>
      <tbody>
        ${farmFoodList
          .map((food) => {
            const individualPrice = parseFloat(
              food.price /
                (food.distributionQuantity / food.individualQuantity),
            ).toFixed(2);
            const totalPrice = (individualPrice * food.quantity).toFixed(2);
            return `
              <tr>
                <td style="border: 1px solid #ddd; padding: 8px;">${food.item}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${food.quantity}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">${food.farmName}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">$${individualPrice}</td>
                <td style="border: 1px solid #ddd; padding: 8px;">$${totalPrice}</td>
              </tr>
            `;
          })
          .join("")}
      </tbody>
    </table>
  `;

  // Calculate the user's order total.
  const grandTotal = UserGrandTotal(farmFoodList).toFixed(2);

  // The final count and all the fees and the payment method.
  const invoiceFooter = `
    <p style="font-size: 14px; text-align:right;">
        <b>Total:</b> $${grandTotal}
    </p>

    <p style="text-align:center; font-size: 12px; "> 
      Payments made by direct deposit.<br/>
      Thank you for your support!
    </p>
  `;

  // Puts everything into one html message.
  const htmlContent = `
    <html>
      <head>
        <title>Invoice</title>
        <style>
          table {
            border-collapse: collapse;
            width: 100%;
          }
          th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
          }
          th {
            background-color: #f2f2f2;
          }
        </style>
      </head>
      <body>
        ${invoiceHeader}
        ${invoiceBody}
        ${invoiceFooter}
      </body>
    </html>
  `;

  const preHtml =
    "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title><style>.order-container {border: 1px solid #000;padding: 10px;margin-bottom: 20px;width: calc(50% - 10px);box-sizing: border-box;}.order-container p, .order-container ul {margin: 0;padding: 0;}</style></head><body>";
  const postHtml = "</body></html>";

  // Adds everything together to be ready for the html.
  const html = preHtml + htmlContent + postHtml;

  // Specify link url
  const url =
    "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);

  // Specify file name
  const filename = `${farmInfo.farmName} Invoice ${invoiceNumber}.doc`;

  // Create download link downloadString
  const downloadLink = document.createElement("a");

  document.body.appendChild(downloadLink);

  // Create a link to the file
  downloadLink.href = url;

  // Setting the file name
  downloadLink.download = filename;

  // triggering the function
  downloadLink.click();

  document.body.removeChild(downloadLink);

  // Return.
  return 0;
}
