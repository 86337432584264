// This is a form that the user uses to send in their application to become a community
// pickup location.  It will update the database and then also send emails to distribution
// location and the user applying.  It is sent in from the MyAccount.  It is different from
// the pickup selection because it loads the distribution locations itself.
import React, { useState, useEffect } from "react";
import firebase from "../../../components/Firebase.js";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LoadingContent from "../../../components/LoadingContent.jsx";
import CommunityHubApplicationForm from "../../../components/userForms/CommunityHubApplicationForm.jsx";

export default function MyAccountBecomeCommunityHostDialog({
  userInfo,
  setSendingEmail,
  handleUserInfoChange,
}) {
  // The database of the firebase.
  const database = firebase.firestore();
  // Whether the dialog is open or not.
  const [open, setOpen] = useState(false);
  // Whether the database is stil being accessed.
  const [loading, setLoading] = useState(false);
  // The distribution location that the community hub is partnered with.
  const [partnerLocation, setPartnerLocation] = useState("");
  // The distribution locations that are currently available.
  const [pickupLocations, setPickupLocations] = useState({});
  // What kind of community hub this is, publicApproval, publicNoApproval, private.
  const [communityHubType, setCommunityHubType] = useState("publicApproval");
  // The name of the community location.
  const [locationName, setLocationName] = useState(
    userInfo.firstName + "'s Community Pickup",
  );
  // Their address.
  const [address, setAddress] = useState("");
  // An address that is close to their home.
  const [obscuredAddress, setObscuredAddress] = useState("");
  // How many people they want max coming to their location.
  const [maxParticipants, setMaxParticipants] = useState("");
  // The available pick up times.
  const [availableTimes, setAvailableTimes] = useState("");
  // Any additional notes about the location.
  const [additionalComments, setAdditionalComments] = useState("");

  // Load the distribution locations that are located in Vancouver.
  useEffect(() => {
    const docRef = database
      .collection("DistributionLocations")
      .doc("Vancouver");

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          // Temp variable to hold the data that we can modify.
          const pickupLocationsTemp = { ...doc.data() };
          // Cycle through all the distribution locations and check if any are inactive.
          Object.keys(doc.data()).forEach((distributionLocation) => {
            // If this location is inactive.
            if (doc.data()[distributionLocation].status === "inactive") {
              // Delete it so that it cannot be selected.
              delete pickupLocationsTemp[distributionLocation];
            }
          });
          // Set the pickup locations state.
          setPickupLocations(pickupLocationsTemp);
          setPartnerLocation(Object.keys(pickupLocationsTemp)[0]);
          setLoading(true);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          setLoading(true);
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
        // setLoading(true)
      });
  }, []);

  // Closes the dialog and resets the fields.
  const handleClose = () => {
    setOpen(false);
    setCommunityHubType("publicApproval");
    setLocationName(userInfo.firstName + "'s Community Pickup");
    setAddress("");
    setObscuredAddress("");
    setMaxParticipants("");
    setAvailableTimes("");
    setAdditionalComments("");
  };

  if (loading) {
    return (
      <div style={{ width: "100%", marginRight: "10px" }}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          BECOME A COMMUNITY HOST
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ padding: "16px 24px 0px" }}
          >
            Become a Community Pickup Location
          </DialogTitle>
          <DialogContent>
            <CommunityHubApplicationForm
              pickupLocations={pickupLocations}
              userInfo={userInfo}
              setSendingEmail={setSendingEmail}
              handleUserInfoChange={handleUserInfoChange}
              handleClose={handleClose}
              partnerLocation={partnerLocation}
              setPartnerLocation={setPartnerLocation}
              communityHubType={communityHubType}
              setCommunityHubType={setCommunityHubType}
              locationName={locationName}
              setLocationName={setLocationName}
              address={address}
              setAddress={setAddress}
              obscuredAddress={obscuredAddress}
              setObscuredAddress={setObscuredAddress}
              maxParticipants={maxParticipants}
              setMaxParticipants={setMaxParticipants}
              availableTimes={availableTimes}
              setAvailableTimes={setAvailableTimes}
              additionalComments={additionalComments}
              setAdditionalComments={setAdditionalComments}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  } else {
    return <LoadingContent inline={true} />;
  }
}
