// This how we set the base delivery fees.  As some community hubs may have
// special delivery fees then we need to establish the delivery fee for each
// location.defaultBaseDeliveryFee is sent in from ConfirmOrderDropOffMessageDialog.jsx
// but not from DeliveryVolunteerEmailsDialog.
export default function SetBaseDeliveryFees(
  communityHubUsers,
  defaultBaseDeliveryFee,
) {
  const baseDeliveryFees = {};
  // Set a temp default base delivery fee.
  let defaultBaseDeliveryFeeTemp = defaultBaseDeliveryFee;
  // If the default base delivery fee is null then we have to find
  // it by looking at the orders from the users.
  if (defaultBaseDeliveryFee == null) {
    // Cycle through the users.
    Object.keys(communityHubUsers).forEach((communityHub) => {
      // The first user is all we need to look at since all the users for a
      // community hub will have the same delivery fee we just need to check the first one.
      const firstUser = Object.values(communityHubUsers[communityHub])[0];
      if (firstUser !== undefined) {
        // Set the default base delivery fee to the pickuplocation's baseFee.
        // We know the user will have a communityPickup since it's at a hub.
        defaultBaseDeliveryFeeTemp =
          firstUser?.communityPickup?.pickupLocation?.baseDeliveryFee;
      }
    });
  }

  // Cycle through the users.
  Object.keys(communityHubUsers).forEach((communityHub) => {
    // Base delivery fee that is for the default location.
    baseDeliveryFees[communityHub] = defaultBaseDeliveryFeeTemp;
    // The first user is all we need to look at since all the users for a
    // community hub will have the same delivery fee we just need to check the first one.
    const firstUser = Object.values(communityHubUsers[communityHub])[0];
    // If there is no firstUser then we just need to skip over that.
    if (firstUser !== undefined) {
      // If the community delivery fee exists or is 0 then update the base delivery fee.
      if (
        firstUser.communityPickup.communityDeliveryFee ||
        firstUser.communityPickup.communityDeliveryFee === 0
      ) {
        baseDeliveryFees[communityHub] =
          firstUser.communityPickup.communityDeliveryFee;
      }
    }
  });

  return { ...baseDeliveryFees };
}
