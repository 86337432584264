// This is to update an the delviery fee of a user.  This can be called multiple
// times if there are multiple users that are at a location.
import firebase from "./../Firebase.js";
import UpdateCommunityOrdersDatabase from "./../../pages/BasketPage/Functions/OrderUpdates/UpdateCommunityOrdersDatabase.js";

export default async function UpdateDeliveryCost(
  user,
  order,
  userInfo,
  orderLog,
  updateOrderLog,
  orderIndex,
  userOrder,
  communityOrderChunks,
  setCommunityOrderChunks,
  newCostOfDelivery,
) {
  const database = firebase.firestore();
  const batch = database.batch();

  // This is used to find the indivdual's order in the database
  const userId = user.split("-");

  // Finds when then user ordered to help find the individual's order in the database
  const userOrderDate = userOrder.orderDate;
  // This holds the user's order and so we make a temp copy to modify.
  const userOrderTemp = { ...userOrder };
  // This is to read the communityPickup so that we can modify it.  We know
  // it will have a communityPickup as we are charging the cost of delivery.
  const updatedCommunityPickup = { ...userOrderTemp.communityPickup };
  // This updates the cost of delivery to the new amount.
  updatedCommunityPickup.costOfDelivery = parseFloat(newCostOfDelivery);
  // This is updates the communityPickup with the new costOfDelivery.
  userOrderTemp.communityPickup = { ...updatedCommunityPickup };

  // The indivdual's order also needs to be updated.  This is the reference to it
  // in firebase.
  const userOrderDocRef = database
    .collection("Users")
    .doc(userId[2])
    .collection("Orders")
    .doc(userOrderDate);

  // This is used to update the document in the DistributionLocations collection
  let communityOrdersDocRef = null;

  // To ensure the screen updates once the changes are saved we need to update the
  // orders that are used to render each order
  const orderLogTemp = [...orderLog];

  // Update the current distribution locations orders in the DistributionLocations
  // collection
  const communityOrdersRef = database
    .collection("DistributionLocations")
    .doc(userInfo.organisationName)
    .collection("CommunityOrders");

  const querySnapshot = await communityOrdersRef
    .where("distributionDate", "==", order.selectedDate)
    .get();

  // Loop through the documents to find the one with the matching user key
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    // Check if the document contains the user key
    if (user in data) {
      communityOrdersDocRef = doc.ref; // Reference to the found document
    }
  });

  // Remove the Dicts as they were added for rendering purposes and should not
  // be saved to the database
  delete userOrderTemp.foodDict;
  delete userOrderTemp.farmDict;

  // Users -> userId -> Orders -> date -> communityOrders/importedOrders ->
  // FirstName-LastName-userId-date
  UpdateCommunityOrdersDatabase(
    "SingleUserOverwrite",
    batch,
    communityOrderChunks,
    setCommunityOrderChunks,
    null,
    order.orderDate,
    user,
    userOrderTemp,
  );

  // Updates the DistributionLocations -> nameofDistributionLocation ->
  // CommunityOrders -> DistributionDate -> FirstName-LastName-userId-date
  if (communityOrdersDocRef) {
    batch.update(communityOrdersDocRef, {
      [user]: { ...userOrderTemp },
    });
  }

  // Updates Users -> userId -> Orders -> date; communityOrders/importedOrders
  // FirstName-LastName-userId-date
  batch.update(userOrderDocRef, {
    communityPickup: userOrderTemp.communityPickup,
  });

  // Set the user order before changes.
  orderLogTemp[orderIndex].communityOrders[user] = { ...userOrderTemp };

  await batch.commit();
  // Updates the orderLog to make sure the screen shows the correct information
  updateOrderLog([...orderLogTemp]);

  console.log("Successfully wrote to database!");
}
