// This is used to set the cost of delivery for a community location.
// This is tracked through the user's orders so we have to update all the user's
// orders to include this change.
import React, { useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CommunityOrderChunksContext } from "../../MyAccount.js";
import TextField from "@material-ui/core/TextField";
import UpdateDeliveryCost from "../../../components/ModifyOrders/UpdateDeliveryCost.js";

export default function UpdateDeliveryCostDialog({
  order,
  userInfo,
  orderLog,
  orderIndex,
  updateOrderLog,
  communityOrders,
  currentCostOfDelivery,
  costOfDeliveriesChanged,
  setCostOfDeliveriesChanged,
}) {
  const [open, setOpen] = useState(false);
  // This is the cost of the delivery and it is set to previously set amount
  // if that exists.
  const [costOfDelivery, setCostOfDelivery] = useState(
    currentCostOfDelivery ?? 0,
  );

  // communityOrderChunks are in {orderdate: [communityOrders, overFlowCommunityOrders1, etc.]}
  const { communityOrderChunks = [], setCommunityOrderChunks = () => {} } =
    useContext(CommunityOrderChunksContext) || {};

  const handleClickOpen = () => {
    setCostOfDelivery(currentCostOfDelivery ?? 0);
    setOpen(true);
  };

  const handleClose = () => {
    setCostOfDelivery(currentCostOfDelivery ?? 0);
    setOpen(false);
  };

  // Save the changes.
  const handleSave = async (e) => {
    e.preventDefault();

    // Cycle through all the communityOrders keys and then update their order to
    // include the new cost of delivery.

    // Update the three locations in the database a user's order is saved.
    for (const user of Object.keys(communityOrders)) {
      await UpdateDeliveryCost(
        user,
        order,
        userInfo,
        orderLog,
        updateOrderLog,
        orderIndex,
        communityOrders[user],
        communityOrderChunks,
        setCommunityOrderChunks,
        costOfDelivery,
      );
    }

    setCostOfDeliveriesChanged(!costOfDeliveriesChanged);
    setOpen(false);
  };

  return (
    <div>
      <Button
        style={{ margin: "5px" }}
        size="small"
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
      >
        SET DELIVERY COST
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ padding: "16px 24px 0px" }}
        >
          The Cost of Delivering to this Community Location
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSave}>
            <TextField
              margin="dense"
              id="costOfDelivery"
              label="Cost of Delivery"
              name="costOfDelivery"
              value={costOfDelivery}
              onChange={(e) => setCostOfDelivery(e.target.value)}
              number
              required
            />
            <DialogActions>
              <Button onClick={() => handleClose()} color="primary">
                Cancel
              </Button>
              {currentCostOfDelivery !== parseFloat(costOfDelivery) && (
                <Button type="submit" color="primary">
                  Save
                </Button>
              )}
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
