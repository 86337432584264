// Displays an individual user's volunteer schedule which allows them to view,
// and redeem credits.
import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../../components/authentication/Auth.js";
import Button from "@material-ui/core/Button";
import EnterIndividualCreditCodeDialog from "../../Dialogs/VolunteerSchedule/EnterIndividualCreditCodeDialog.jsx";
import LoadMoreVolunteerSchedule from "../../Functions/VolunteerSchedule/LoadMoreVolunteerSchedule.js";
import ExportToCalendarButton from "./ExportToCalendarButton.jsx";
import DeliveryVolunteerEmailsDialog from "../../Dialogs/VolunteerSchedule/DeliveryVolunteerEmailsDialog.jsx";

export default function IndividualVolunteerSchedule({
  volunteerSchedule,
  setVolunteerSchedule,
  setLoading,
  lastLoadedQuarter,
  setLastLoadedQuarter,
}) {
  // Show the displayed schedule which will only show the shifts of the current volunteer.
  const [displayedSchedule, setDisplayedSchedule] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const { userInfo, currentUser, handleUserInfoChange } =
    useContext(AuthContext);

  // Sets the months to an array.
  const months = [
    "Jan.",
    "Feb.",
    "Mar.",
    "Apr.",
    "May",
    "Jun.",
    "Jul.",
    "Aug.",
    "Sept.",
    "Oct.",
    "Nov.",
    "Dec.",
  ];

  // Set the displayed schedule from the total list.
  useEffect(() => {
    // The temp schedule.
    const displayedScheduleTemp = [];
    // Cycle through all the quarters that have been loaded into the volunteer schedule.
    volunteerSchedule.forEach((volunteerScheduleQuarter, index) => {
      // Set the displayedScheduled for this quarter and intialize it.
      displayedScheduleTemp[index] = {};
      // Cycle through the schedule starting with the date.
      Object.keys(volunteerScheduleQuarter).forEach((date) => {
        // Check that the date is not equal to timePeriod as that is one key we don't
        // want to cycle through.
        if (date !== "timePeriod") {
          // Cycle through all the different positions.
          Object.keys(volunteerScheduleQuarter[date]).forEach((position) => {
            // Cycle through the shifts available for this position.
            Object.keys(volunteerScheduleQuarter[date][position]).forEach(
              (shift) => {
                // Skip doing the location and the creditValue keys as they aren't the ones
                // we want to look at.
                if (shift !== "location" && shift !== "creditValue") {
                  // Check that the current volunteer is in the volunteer schedule.
                  const volunteerIncluded = volunteerScheduleQuarter[date][
                    position
                  ][shift].volunteers.some(
                    (volunteer) => volunteer.userId === userInfo.userId,
                  );
                  // If the volunteer is included in this shift then add it to the displayed schedule.
                  if (volunteerIncluded) {
                    // If this date hasn't been added yet then set it a new dictionary.
                    if (displayedScheduleTemp[index][date] === undefined) {
                      displayedScheduleTemp[index][date] = {};
                    }
                    // If the position hasn't been added yet then we have to create the new
                    // dictionary with the values of the creditValue and the location.
                    if (
                      displayedScheduleTemp[index][date][position] === undefined
                    ) {
                      displayedScheduleTemp[index][date][position] = {
                        creditValue:
                          volunteerScheduleQuarter[date][position].creditValue,
                        location:
                          volunteerScheduleQuarter[date][position].location,
                      };
                    }
                    // Add the shift to the volunteer shift.
                    displayedScheduleTemp[index][date][position][shift] =
                      volunteerScheduleQuarter[date][position][shift];
                  }
                }
              },
            );
          });
        }
      });
    });

    setDisplayedSchedule([...displayedScheduleTemp]);
  }, []);

  return (
    <div>
      {displayedSchedule.length !== 0 && lastLoadedQuarter !== null && (
        <Button
          onClick={() =>
            LoadMoreVolunteerSchedule(
              volunteerSchedule,
              setVolunteerSchedule,
              setLoading,
              lastLoadedQuarter,
              setLastLoadedQuarter,
            )
          }
          variant="contained"
          color="primary"
        >
          LOAD PREV SCHEDULE
        </Button>
      )}

      {displayedSchedule.map((displayedScheduleQuarter, i) => (
        <>
          {/* add button to export to calendar */}
          {!(displayedSchedule.length > 1) &&
            Object.keys(displayedScheduleQuarter).length > 0 && (
              <ExportToCalendarButton
                volunteerSchedule={volunteerSchedule[0]}
              />
            )}
          {Object.keys(displayedScheduleQuarter)
            .sort()
            .map((date) => (
              <div key={date}>
                <span
                  style={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  <h5>
                    <u>
                      {months[parseInt(date.substr(0, 2))]} {date.substr(2, 2)}{" "}
                      {volunteerSchedule[i].timePeriod.substr(0, 4)}
                    </u>
                  </h5>
                </span>
                <span style={{ display: "flex", gap: "50px" }}>
                  {Object.keys(displayedScheduleQuarter[date]).map(
                    (position) => (
                      <>
                        {Object.keys(
                          displayedScheduleQuarter[date][position],
                        ).map((shift) => (
                          <>
                            {shift !== "creditValue" &&
                              shift !== "location" && (
                                <div>
                                  Position : {position} <br />
                                  Location :{" "}
                                  {
                                    displayedScheduleQuarter[date][position]
                                      .location
                                  }{" "}
                                  <br />
                                  Time :{" "}
                                  {shift.slice(-8, -6) +
                                    ":" +
                                    shift.slice(-6, -4)}{" "}
                                  to{" "}
                                  {shift.slice(-4, -2) + ":" + shift.slice(-2)}{" "}
                                  <br />
                                  Credit Value :{" "}
                                  {
                                    displayedScheduleQuarter[date][position]
                                      .creditValue
                                  }{" "}
                                  <br />
                                  {
                                    // Find the volunteer with the matching userId
                                    (() => {
                                      const volunteers =
                                        displayedScheduleQuarter[date][
                                          position
                                        ][shift].volunteers;
                                      const volunteer = volunteers.find(
                                        (v) => v.userId === userInfo.userId,
                                      );

                                      if (
                                        !volunteer ||
                                        !volunteer.communityLocationAccess
                                      )
                                        return null;

                                      return (
                                        <div>
                                          Community Location Access:{" "}
                                          {volunteer.communityLocationAccess}
                                          <DeliveryVolunteerEmailsDialog
                                            date={date}
                                            year={volunteerSchedule[
                                              i
                                            ].timePeriod.substr(0, 4)}
                                            communityLocation={
                                              volunteer.communityLocationAccess
                                            }
                                          />
                                        </div>
                                      );
                                    })()
                                  }
                                  <EnterIndividualCreditCodeDialog
                                    volunteerSchedule={volunteerSchedule}
                                    setVolunteerSchedule={setVolunteerSchedule}
                                    indexOfQuarter={i}
                                    date={date}
                                    position={position}
                                    shift={shift}
                                    userInfo={userInfo}
                                    handleUserInfoChange={handleUserInfoChange}
                                  />
                                </div>
                              )}
                          </>
                        ))}
                      </>
                    ),
                  )}
                </span>
              </div>
            ))}
        </>
      ))}
    </div>
  );
}
