// This function is used to send in to the email batcher to help send off
// the emails for drop off emails.
import firebase from "../../../../../components/Firebase.js";

export default async function OrderDropOffEmails(item) {
  const [
    userEmailsNoLocation,
    user,
    setSendingEmails,
    greeting,
    userDropOffMessages,
  ] = item;

  try {
    // Sets the email cloud function
    const customMessageToUsers = firebase
      .functions()
      .httpsCallable("customMessageToUsers");

    // Sets the destination of the email to the user's email.
    const dest = [userEmailsNoLocation[user]];
    // Sets this to true to display the loading dialog while emails are sent.
    setSendingEmails(true);

    // Calls the cloud function.
    await customMessageToUsers({
      dest,
      subject: "Order Has Been Dropped Off",
      greeting,
      firstName: user.split("-")[0],
      customMessage: userDropOffMessages[user],
    });
  } catch (error) {
    // Getting the Error details.
    const message = error.message;
    // Throw the error to propagate it
    throw new Error(`Failed to send email to ${user}: ${message}`);
  }
}
