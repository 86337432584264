// This dialog appears when an admin user wants to add more information
// to a volunteer that might be required for any special instructions for this
// user volunteer task.  This is used for delivery drivers to give them a location
// where they are delivering.
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InfoIcon from "@mui/icons-material/Info";
import firebase from "../../../../components/Firebase.js";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";

// This is the dialog box that opens when a user clicks the add more button
function VolunteerAddMoreInfoBox({
  open,
  onClose,
  handleSave,
  communityLocations,
  selectedLocation,
  setSelectedLocation,
}) {
  // If the user closes or cancels then we will close the dialog and if they hit cancel
  // their changes are reset.
  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" style={{ padding: "16px 24px 0px" }}>
        Add More Information to this Volunteer's Role. <InfoIcon />
      </DialogTitle>
      <DialogContent>
        {/* Community Location Radio Group */}
        <FormControl style={{ marginTop: "16px" }}>
          <FormLabel id="radio-buttons-group-location">
            Select Location
          </FormLabel>
          <RadioGroup
            aria-label="community-location"
            name="community-location"
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
          >
            {communityLocations.map((location) => (
              <FormControlLabel
                key={location}
                value={location}
                control={<Radio />}
                label={location}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            CLOSE
          </Button>
          {selectedLocation !== "" && (
            <Button onClick={() => handleSave()} color="primary">
              SAVE
            </Button>
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

VolunteerAddMoreInfoBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function VolunteerAddMoreInfoDialog({
  selectedPosition,
  volunteerSchedule,
  setVolunteerSchedule,
  i,
  date,
  shift,
  index,
}) {
  const [open, setOpen] = useState(false);
  // These are all the community locations.
  const [communityLocations, setCommunityLocations] = useState([]);
  // This is the location selected for this user.
  const [selectedLocation, setSelectedLocation] = useState("");
  const database = firebase.firestore();

  const handleClickOpen = () => {
    setOpen(true);

    // Get the existing communityLocationAccess if it exists
    const existingLocation =
      volunteerSchedule[i]?.[date]?.[selectedPosition]?.[shift]?.volunteers?.[
        index
      ]?.communityLocationAccess || "";

    setSelectedLocation(existingLocation);
  };

  const handleClose = () => {
    setSelectedLocation("");
    setOpen(false);
  };

  const handleSave = () => {
    const batch = database.batch();

    // The volunteer schedule in a temp dict to be edited.
    const volunteerScheduleTemp = [...volunteerSchedule];

    // This is the volunteer schedule quarter that we can update.
    const volunteerScheduleQuarterTemp = { ...volunteerSchedule[i] };

    // Update the user's communityLocationAccess.
    const volunteerTemp = {
      ...volunteerScheduleQuarterTemp[date][selectedPosition][shift].volunteers[
        index
      ],
    };
    // Set the location to the selection community location.
    volunteerTemp.communityLocationAccess = selectedLocation;
    // Update the volunteer in the schedule with this updated object.
    volunteerScheduleQuarterTemp[date][selectedPosition][shift].volunteers[
      index
    ] = { ...volunteerTemp };

    // The volunteer schedule for this time period.
    const docRef = database
      .collection("VolunteerSchedule")
      .doc(volunteerScheduleTemp[i].timePeriod);

    // update the database.
    batch.set(docRef, volunteerScheduleQuarterTemp, { merge: true });

    batch.commit();

    // Update the current volunteer schedule.
    volunteerScheduleTemp[i] = { ...volunteerScheduleQuarterTemp };
    setVolunteerSchedule([...volunteerScheduleTemp]);

    setOpen(false);
  };

  // Load the volunteer schedule from the database.
  useEffect(() => {
    // We have to load the community locaitons.
    const communityLocationsDocRef = database.collection("CommunityLocations");
    const communityLocationsTemp = [];

    communityLocationsDocRef
      .get()
      .then((collection) => {
        collection.docs.forEach((doc) => {
          if (doc.exists) {
            communityLocationsTemp.push(doc.data().locationName);
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        });
        setCommunityLocations(communityLocationsTemp);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }, []);

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        ADD MORE
      </Button>

      <VolunteerAddMoreInfoBox
        open={open}
        onClose={handleClose}
        handleSave={handleSave}
        communityLocations={communityLocations}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
      />
    </div>
  );
}
