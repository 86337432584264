// This is called to check by the basket and myAccount pages of a distribution location
// to find what a community host's current status is in terms of
// how each user is charged for the delivery fee and how many people have ordered.
import React from "react";

export default function CommunityLocationStatus({
  communityOrders,
  userInfo,
  costOfDelivery,
}) {
  // The maximum max participants that have been set by the community host that can
  // pick up from this location in a given week.
  let maxParticipants = 0;
  // The delivery fee base
  let baseDeliveryFee = userInfo.baseDeliveryFee;
  // This is how much the delivery fee is once divided amongst all users
  let deliveryFee = baseDeliveryFee;
  // Contains the list of userIds of all the users that will be used to sum up
  // the total user count.  The length of this array is total users
  const listOfUsers = [];
  // Since the deliveryFee changes depending on how many people have ordered
  // it might change and so we just want to say if it final or not.
  let deliveryFeeStatus = "* This fee is not final and may change.";

  // Cycles through all the orders
  Object.keys(communityOrders).forEach((order) => {
    const userId = order.split("-")[2];
    // Determine the maxParticipants of this location.  It only needs to be done
    // once, but it gets done over and over again.
    maxParticipants = communityOrders[order].communityPickup.maxParticipants;

    // If the delivery fee is defined or equal to 0 then update the base delivery fee.
    if (
      communityOrders[order].communityPickup.communityDeliveryFee ||
      communityOrders[order].communityPickup.communityDeliveryFee === 0
    ) {
      baseDeliveryFee = parseFloat(
        communityOrders[order].communityPickup.communityDeliveryFee,
      );
      deliveryFee = parseFloat(baseDeliveryFee);
    }

    // Checks to see if this user has already been counted
    if (!listOfUsers.includes(userId)) {
      // Adds the new user to the array
      listOfUsers.push(userId);
    }
    // Checks if the delivery fee exists.  If it does then it will be the
    // final price.  We will also set it to the price that is stored as calculating
    // it might not be right in case someone's order was removed after the fact.
    if (communityOrders[order].deliveryFee) {
      deliveryFeeStatus = "";
      deliveryFee = parseFloat(communityOrders[order].deliveryFee);
    }
  });

  // We want to check if the final price hasn't been determined yet and that means
  // that the deliveryFee will be the base fee divided by the number of users.
  if (deliveryFeeStatus === "* This fee is not final and may change.") {
    deliveryFee = parseFloat(baseDeliveryFee / listOfUsers.length);
  }

  // Return the html print of the current location's status
  return (
    <p style={{ fontSize: "18px" }}>
      Delivery Fee: ${deliveryFee.toFixed(2)}
      {deliveryFeeStatus} <br />
      Customers: {listOfUsers.length}/{maxParticipants}
      {costOfDelivery && (
        <span>
          <br />
          Cost of Delivery: ${costOfDelivery.toFixed(2)}
        </span>
      )}
    </p>
  );
}
