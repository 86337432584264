import PriceTotals from "../../../functions/PriceTotals.js";
import PackageTotals from "../../../functions/PackageTotals.js";
import * as XLSX from "xlsx";

/**
 * Calculates an order's total package fee.
 *
 * @param {*} userOrder
 * @returns {number} an order's total cost
 */
export function calculatePackageFeeTotal(userOrder) {
  // The total packaging fee.
  let packageFeeTotal = 0;

  // A dictionary of the the farms(keys) and the total packaging fees to them (values)
  const packageTotals = PackageTotals(userOrder.foodList);

  // Cycle through the package Totals and add them all up.
  Object.keys(packageTotals).forEach((packageTotal) => {
    packageFeeTotal += packageTotals[packageTotal];
  });

  return packageFeeTotal;
}

export function calculateTotalCost(userOrder) {
  // The total including the donation or subsidy
  let grandTotal = 0;

  // A dictionary of the the farms(keys) and the total packaging fees to them (values)
  const farmTotals = PriceTotals(userOrder.foodList, true);

  // A dictionary of the the farms(keys) and the total packaging fees to them (values)
  const packageTotals = PackageTotals(userOrder.foodList);

  // The total volunteer Fee Cash
  const volunteerFeeCash = userOrder.cashPortion;

  // Sum up the grandTotal of all the farms together.
  Object.keys(farmTotals).forEach((farmTotal) => {
    grandTotal += farmTotals[farmTotal];
  });

  // Cycle through the package Totals and add them all up.
  Object.keys(packageTotals).forEach((packageTotal) => {
    grandTotal += packageTotals[packageTotal];
  });

  // Add or subtract the donation or the subsidy amounts.
  if (userOrder.customerContribution === "donation") {
    grandTotal += userOrder.donationSubsidy;
  } else {
    grandTotal -= userOrder.donationSubsidy;
  }

  // If the user has a cash volunteer portion greater than 0 then add that to the
  // grandTotal.
  if (parseFloat(volunteerFeeCash) > 0) {
    grandTotal += parseFloat(volunteerFeeCash);
  }
  // If the user has a delivery fee portion greater than 0 then add that to the
  // grandTotal.
  if (parseFloat(userOrder.deliveryFee) > 0) {
    grandTotal += userOrder.deliveryFee;
  }

  // Make sure that the grandTotal isn't ever negative.
  if (grandTotal < 0 && grandTotal > -0.01) {
    grandTotal = 0;
  }

  return grandTotal;
}

/**
 * @param {string} orderDate
 *
 * Converts order date from Community Orders into YYYY-MM-DD format.
 * Ex. 1994-11-13
 */
function formatOrderDate(orderDate) {
  const splitOrderDate = orderDate.split("-");
  const year = splitOrderDate[2];
  const month = splitOrderDate[0];
  const day = splitOrderDate[1];

  return `${year}-${month}-${day}`;
}

/**
 *  Creates a .xls format spreadsheet that includes the following columns:
 *    * Customer Index
 *    * Partipcation Cash Used
 *    * Partipication Credits Used
 *    * Subsidy
 *    * Donation
 *    * Package Fees
 *    * Total Order Cost
 */
export function createOrdersSpreadsheet(communityOrders, distributionDate) {
  const orderMatrix = [];
  const columnNamesList = [
    "Customer Index",
    "User ID",
    "Participation Cash Used",
    "Participation Credits Used",
    "Subsidy",
    "Donation",
    "Package Fees",
    "Delivery Fees",
    "Total Cost",
    "Distribution Date",
  ];

  // Adding headers for xls file.
  orderMatrix.push(columnNamesList);
  let customerIndex = 1;
  for (const location of Object.values(communityOrders)) {
    for (const [user, order] of Object.entries(location)) {
      const userId = user.split("-").pop();
      const userDonated = order.customerContribution === "donation";
      orderMatrix.push([
        `Customer ${customerIndex}`,
        userId,
        order.cashPortion,
        order.creditPortion,
        userDonated ? 0 : order.donationSubsidy,
        userDonated ? order.donationSubsidy : 0,
        calculatePackageFeeTotal(order),
        order.deliveryFee == null
          ? 0
          : parseFloat(order.deliveryFee).toFixed(2),
        Math.round(calculateTotalCost(order) * 100) / 100,
        order.distributionDate,
      ]);
      customerIndex++;
    }
  }

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(orderMatrix);
  const worksheetColsWidths = [];

  for (let i = 0; i < columnNamesList.length; i++) {
    // This sets each columns width.
    worksheetColsWidths.push({
      wch: 25,
    });
  }

  worksheet["!cols"] = worksheetColsWidths;

  XLSX.utils.book_append_sheet(workbook, worksheet, "Order Summary");
  XLSX.writeFile(
    workbook,
    `${formatOrderDate(distributionDate)}-weekly-orders-summary.xls`,
  );
}
